import $ from 'jquery';
import 'slick-slider';
import {gsap} from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

gsap.registerPlugin(ScrollTrigger)

const slider = () => {
  const section = document.querySelector('.js-slider-section');

  const lineOne = document.querySelector('.js-hero-section-line-one')
  const lineOneWrapper = lineOne.querySelector('.js-line-wrapper')
  const lineTwo = document.querySelector('.js-hero-section-line-two')
  const lineTwoWrapper = lineTwo.querySelector('.js-line-wrapper')

  const tl = gsap.timeline({
    scrollTrigger: {
      trigger: section,
      start: `top center`,
      end: `+=300%`,
      scrub: 1.5,
    },
  })

  tl.addLabel("start")
    .to(lineOneWrapper, { x: '-2%', duration: 3 }, "start")
    .to(lineTwoWrapper, { x: '2%', duration: 3 }, "start")


  const $sliderFor = $('.slider-for')
  const $sliderNav = $('.slider-nav')

  $sliderFor.slick({
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: true,
    dots: false,
    fade: true,
    infinite: true,
    asNavFor: '.slider-nav',
    prevArrow: $('.prev'),
    nextArrow: $('.next')
  });

  $sliderNav.slick({
    slidesToShow: 1,
    slidesToScroll: 1,
    asNavFor: '.slider-for',
    dots: false,
    infinite: true,
    arrows: false,
    centerMode: true,
    focusOnSelect: false,
    variableWidth: true
  });

  $sliderFor.slick('slickGoTo', 5)
  $sliderNav.slick('slickGoTo', 5)
}

export default slider;

